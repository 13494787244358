import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.notEmpty)?_c('validation-provider',{attrs:{"name":_vm.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.disabled,"error-messages":errors},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true)}):_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.disabled},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c(VDatePicker,{attrs:{"active-picker":_vm.activePicker,"no-title":"","first-day-of-week":1,"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveDate},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }