<template>
  <v-menu
    ref="menu"
    v-model="menuDate"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider v-if="notEmpty" v-slot="{ errors }" :name="label" rules="required">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          :disabled="disabled"
          :error-messages="errors"
          v-on="on"
        />
      </validation-provider>
      <v-text-field
        v-else
        v-model="dateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :disabled="disabled"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="model"
      :active-picker.sync="activePicker"
      no-title
      :first-day-of-week="1"
      min="1950-01-01"
      @change="saveDate"
    />
  </v-menu>
</template>

<script>
import dateF from '../../services/date.service'

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
  },
  props: ['date', 'label', 'disabled', 'notEmpty'],
  data() {
    return {
      activePicker: null,
      menuDate: false,
      model: false,
    }
  },
  computed: {
    dateFormatted() {
      return dateF.formatWithoutDay(this.model)
    },
  },
  watch: {
    menuDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    date(val) {
      this.model = val
    },
  },
  mounted() {
    this.model = this.date
  },
  methods: {
    saveDate(date) {
      //this.$refs.menu.save(date)
      this.$emit('update:date', date)
      this.menuDate = false
    },
  },
}
</script>

<style></style>
